import './App.css';
import React, { useState, useEffect } from 'react';

// import { ApolloProvider } from '@apollo/client';
// import { ApolloClient, InMemoryCache } from '@apollo/client'
import AllMatchList from './components/matchList/allMatchList';
import Header from './components/header/header';
import FetchData from './components/commonComponent/fetchData';
import QueryData from './components/commonComponent/queryData';
import { ValueContext } from './contexts/context';

// const client = new ApolloClient({
//   uri: 'http://localhost:4000',
//   cache: new InMemoryCache()
// });
function checkArryEquivalent (targetAry, compareAry) {
  let equiCheck = true
  if (targetAry.length !== compareAry.length) {
    equiCheck =  false
  } else {
    targetAry.forEach((each) => {
      if (compareAry.indexOf(each) === -1) {
        equiCheck = false
      }
    })
  }
  return equiCheck
}

function queryAllMatchDataCache (allMatchDataCache, positionAry, championAry) {
  const allMatchData = allMatchDataCache.filter((eachAllMatchData) => {
    const eachPositionAry = eachAllMatchData.positionAry;
    const eachChampionAry = eachAllMatchData.championAry;
    const positionEquiCheck = checkArryEquivalent(eachPositionAry, positionAry)
    const championEquiCheck = checkArryEquivalent(eachChampionAry, championAry)
    return positionEquiCheck && championEquiCheck
  })
  // console.log(allMatchDataCache);
  return allMatchData
}

function App() {
  const fetchData = FetchData()
  const [allMatchData, setAllMatchData] = useState(null)
  const [allMatchDataCache, setAllMatchDataCache] = useState([])
  const [positionAry, setPositionAry] = useState([])
  const [championAry, setChampionAry] = useState([])
  const languageAry = ['ko_KR', 'es_ES']
  // const languageAry = ['es_ES']
  useEffect(() => {
    async function queryData() {
      // fetchData && await QueryData(fetchData.availalbeVerionAry, fetchData.allRuneIdObj, fetchData.allSummonerSpellObj, languageAry, client, positionAry, championAry ).then((res) => {
      fetchData && await QueryData(fetchData.availalbeVerionAry, fetchData.allRuneIdObj, fetchData.allSummonerSpellObj, languageAry, positionAry, championAry ).then((res) => {
        // console.log(res)
        setAllMatchData(res)
        const newAllMatchDataCache = [...allMatchDataCache]
        newAllMatchDataCache.push({
          positionAry,
          championAry,
          allMatchData: res,
        })
        setAllMatchDataCache(newAllMatchDataCache)
      })
    }

    const allMatchDataCacheAry = queryAllMatchDataCache(allMatchDataCache, positionAry, championAry) 
    if (allMatchDataCacheAry.length === 0) {
      // console.log('query');
      queryData()
    } else {
      // console.log('cache');
      setAllMatchData(allMatchDataCacheAry[0].allMatchData)
    }
  },[fetchData, positionAry, championAry])

  return (
    <div className="App">

      
      {
        fetchData && (
          // <ApolloProvider client={client}>
            <div>
            <ValueContext.Provider value={{positionAry, setPositionAry, championAry, setChampionAry}}>
              <Header allChampionObj={fetchData.allChampionObj} /> 
                
            </ValueContext.Provider>
              {
                allMatchData && <AllMatchList availalbeVerionAry={fetchData.availalbeVerionAry} languageAry={languageAry} allMatchData={allMatchData} allItemObj={fetchData.allItemObj} allChampionObj={fetchData.allChampionObj}/>
              }

            </div>
              
          // </ApolloProvider> 
        )
      }
      
    </div>
  );
}

export default App;
