// import React, { useEffect, useState } from 'react';
import VideoThumbnailSlide from './videoThumbnailSlide/videoThumbnailSlide';
import styles from './header.module.css'
import PositionSelect from './positionSelect/positionSelect';
import ChampionSelect from './championSelect/championSelect';
import { useContext } from 'react';
import { ValueContext } from '../../contexts/context';
import channelMarkImg from '../../images/channelmark.png'
const Header = ({ allChampionObj}) => {
    const {setPositionAry, setChampionAry} = useContext(ValueContext)
    return (
        <div className={styles.header}>
            <div className={styles.channelMark}>

                <img src={channelMarkImg} alt="channelMark" />
            </div>
            <VideoThumbnailSlide />
            <div className={styles.selectOption}>
                <PositionSelect setPositionAry={setPositionAry}/>
                <ChampionSelect allChampionObj={allChampionObj}setChampionAry={setChampionAry} />
            </div>
        </div>
    )
}

export default Header;