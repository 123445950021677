
import { useRef, useState } from 'react';
import styles from './championSelect.module.css'


const ChampionSelect = ({ allChampionObj, setChampionAry }) => {

    const searchSvg = (<svg role="img" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><g fill="none" stroke="#ffffff"><path strokeLinecap="square" strokeWidth="2.5" d="M18.5 18.3l-5.4-5.4"/><circle cx="9" cy="9" r="6" strokeWidth="3"/></g></svg>)

    const [openSelectionBox, setOpenSelectionBox] = useState(false)
    const selectionBoxRef = useRef()
    const championSearchInputRef = useRef()

    function searchClick () {
        const selectionBoxInnerRef = selectionBoxRef.current.children[0]
        if (openSelectionBox === false) {
            championSearchInputRef.current.style.width = "100px"
            championSearchInputRef.current.style.opacity = '1'
            selectionBoxInnerRef.style.visibility = "visible"
            selectionBoxRef.current.style.maxHeight = '144px'
            selectionBoxRef.current.style.marginBottom = '5px'
            setOpenSelectionBox(true)
        } else {
            championSearchInputRef.current.style.width = "0px"
            championSearchInputRef.current.style.opacity = '0'
            selectionBoxInnerRef.style.visibility = "hidden"
            selectionBoxRef.current.style.maxHeight = '0px'
            selectionBoxRef.current.style.marginBottom = '0px'
            setOpenSelectionBox(false)
        }

    }
    
    function championVisible (searchChampNameTerm) {
        const championAllEle = selectionBoxRef.current.children[0].children
        selectionBoxRef.current.scrollTop  = '0px'
        for (let i = 0 ; i < championAllEle.length ; i++) {
            const championKeyName = championAllEle[i].getAttribute('keyname')
            const languageChampNameAry = Object.values(allChampionObj[championKeyName].language)
            languageChampNameAry.push(championKeyName.toLowerCase())
            for (const champName of languageChampNameAry) {
                let seletedChampion = championAllEle[i].children[1].style.opacity === "1" ? true : false
                if (champName.toLowerCase().includes(searchChampNameTerm.toLowerCase())) {
                    
                    championAllEle[i].style.visibility = 'visible'
                    championAllEle[i].style.width = '48px'
                    championAllEle[i].style.height = '48px'
                    break;
                } else {
                    if (seletedChampion === false) {
                        // championAllEle[i].style.display = 'none'
                        championAllEle[i].style.visibility = 'hidden'
                        championAllEle[i].style.width = '0px'
                        championAllEle[i].style.height = '0px'
                    }
                }
            }
        }

    }

    const [seletedChampionObj, setSeletedChampionObj] = useState({})

    function championClick(e) {
        let currentTarget = e.target;
        const altName = e.target.getAttribute('alt');
        if (altName === 'selectBox') {
            return
        }
        let currentAltAttribute = currentTarget.getAttribute('alt')
        
        while (currentAltAttribute !== 'champion') {
            currentTarget = currentTarget.parentNode
            currentAltAttribute = currentTarget.getAttribute('alt')
        }
        // let parentTarget = currentTarget;
        const newSelectedChampionObj = {...seletedChampionObj}
        const keyname = currentTarget.getAttribute('keyname')
        const currentOpacity = currentTarget.children[1].style.opacity
        if (currentOpacity === '1') {
            currentTarget.children[0].style.opacity = '0'
            currentTarget.children[1].style.opacity = '0'
            delete newSelectedChampionObj[keyname]
        } else {
            currentTarget.children[0].style.opacity = '0.5'
            currentTarget.children[1].style.opacity = '1'
            Object.assign(newSelectedChampionObj, {
                [keyname] : true
            })
        }
        setSeletedChampionObj(newSelectedChampionObj)
        setChampionAry(Object.keys(newSelectedChampionObj))

    }
    
    return (
        <div className={styles.champion_Select}>
            <div  className={styles.select_Title}>
                <div onClick={searchClick}>
                    CHAMPION
                </div >
                <div onClick={searchClick} className={styles.searchIcon}>
                {searchSvg}
                </div>
                <input ref={championSearchInputRef} className={styles.searchInput} type="text" onChange={(event) => {
                    championVisible(event.target.value)
                }}/>
            </div>
            <div ref={selectionBoxRef} className={styles.select_Option_Box} alt="selectBox">
                <div onClick={championClick} className={styles.select_Option} alt="selectBox">
                    {
                        allChampionObj && Object.entries(allChampionObj).map((res,index) => {
                            // if (index > 1) {
                            //     return
                            // }
                            const version = res[1].version
                            const championName = res[0];
                            const spriteName = res[1].image.sprite;
                            const spritePositionX = res[1].image.x;
                            const spritePositionY = res[1].image.y;
                            const spriteUrl = `https://ddragon.leagueoflegends.com/cdn/${version}/img/sprite/${spriteName}`
                            const eachStyle = {
                                backgroundImage : `url(${spriteUrl})`,
                                backgroundPosition: `-${spritePositionX}px -${spritePositionY}px`
                            }
                            const key = `${index}-${championName}`
                            return (
                                <div key={key} style={eachStyle} keyname={championName} alt="champion">
                                    <div></div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 48"><path fill="#ffffff" d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ChampionSelect;