
import styles from './matchList.module.css'
import ItemPriority from './itemPriority/itemPriority';
import SkillPriority from './skillPriority/skillPriority';
import TextToFit from '../commonComponent/textToFit';

const MatchList = ({ laguage, availalbeVerionAry, allMatchData, allItemObj, allChampionObj}) => {
    // console.log(allItemObj);
    // console.log(allChampionObj);
    const convertedLightStaticDataAry = allMatchData['covertedDataObj'][laguage]
    
    function seeDetailClick(e) {
        let currentTarget = e.target;
        let currentAltAttribute = currentTarget.getAttribute('alt')
        // console.log(currentAltAttribute);
        if (currentAltAttribute === 'youtubeLink') {

        } else {
            while (currentAltAttribute !== "matchLane") {
                currentTarget = currentTarget.parentNode
                currentAltAttribute = currentTarget.getAttribute('alt')
            }
            // const headerEle = currentTarget.children[0]
            const detailEleBox = currentTarget.children[1]
            const detailEle = currentTarget.children[1].children[0]
            const detailEleVisibilty = detailEle.style.visibility
            const detailHeight = detailEle.offsetHeight;
            // console.log(detailEleVisibilty);
            if (detailEleVisibilty !== 'hidden') {
                detailEle.style.visibility = "hidden"
                detailEle.style.opacity = "0"
                detailEleBox.style.height = "0px"
    
            } else {
                detailEle.style.visibility = "visible"
                detailEle.style.opacity = "1"
                detailEleBox.style.height = `${detailHeight}px`
            }
        }
    }
    function championSpriteStyle (championName) {


        const championInfo = allChampionObj[championName]
        const version = championInfo.version
        const spriteName = championInfo.image.sprite;
        const spritePositionX = championInfo.image.x;
        const spritePositionY = championInfo.image.y;
        const spriteUrl = `https://ddragon.leagueoflegends.com/cdn/${version}/img/sprite/${spriteName}`
        const eachStyle = {
            backgroundImage: `url(${spriteUrl})`,
            backgroundPosition: `-${spritePositionX}px -${spritePositionY}px`
        }
        // console.log(eachStyle);
        return eachStyle
    }
    const EachLane = ({lightStaticData, availableVersion}) => {
        let textToFit =  TextToFit(110)
        const proCheck = lightStaticData.summonerProNick !== "" ? true : false
        const headerNameText = proCheck === true 
        ? lightStaticData.summonerProNick
        : lightStaticData.championName
        const headerNameStyle = proCheck === true 
        ? { color : "rgb(155, 255, 41)" }
        : {}
        return (
            <div className={styles.matchLane} alt="matchLane" >
                <div onClick={seeDetailClick} className={styles.matchLane_Header}>
                    <div className={styles.matchLane_Left}>
                        <div className={styles.left_Basic}>
                            {lightStaticData.teamPositionSvg}
                            <div style={{fontWeight: "700"}}>{lightStaticData.version}</div>
                            <div alt="youtubeLink">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="48" height="48" viewBox="0 0 100 100">
                                    <path fill="#ffffff" d="M 95,50 5,95 5,5 z"/>
                                </svg>
                                <a  href={lightStaticData.videoLink} target="_blank" rel="noreferrer" alt="youtubeLink">
                                </a>
                            </div>
                        </div>
                        <div alt="championImgBox">
                            <div style={championSpriteStyle(lightStaticData.championName)} alt="championImg"></div>
                        </div>
                            {/* <img src={lightStaticData.championImg} alt="championImg" /> */}
                        <div className={styles.left_Spell}>
                            <img src={lightStaticData.spell[0]} alt="spell" />
                            <img src={lightStaticData.spell[1]} alt="spell" />
                        </div>
                    </div>
                    <div className={styles.matchLane_Right}>
                        <div className={styles.left_Top}>
                            <img src={lightStaticData.runeStat.primaryStyle[0]} alt="headerKeyRune" />
                            <div {...textToFit} style={headerNameStyle} alt="headerName">
                            {
                                headerNameText
                            }
                            </div>
                            <div className={styles.vsChamp}>
                                <div alt="vsText">VS</div>
                                <div alt="vsChampBox">
                                    <div style={championSpriteStyle(lightStaticData.vsChampionName)} alt="vsChamp"></div>
                                </div>
                                {/* <img src={lightStaticData.vsChampionImg} alt="vsChamp" /> */}
                            </div>
                        </div>
                        <div className={styles.left_Bottom}>
                            {
                                lightStaticData.itemBuilInfo.iB.map((itemId, index) => {
                                    const itemInfo = allItemObj[itemId]
                                    const spriteName = itemInfo.image.sprite;
                                    const spritePositionX = itemInfo.image.x;
                                    const spritePositionY = itemInfo.image.y;
                                    const spriteUrl = `https://ddragon.leagueoflegends.com/cdn/${availableVersion}/img/sprite/${spriteName}`
                                    const eachStyle = {
                                        backgroundImage : `url(${spriteUrl})`,
                                        backgroundPosition: `-${spritePositionX}px -${spritePositionY}px`
                                    }
                                    let key = `${index}-${itemId}`
                                    return (
                                        <div key={key} alt="headerItemBox">
                                            <div style={eachStyle} alt="headerItem"></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.matchLane_Detail_Box}>
                    <div style={{visibility: "hidden"}} className={styles.matchLane_Detail}>
                        <div className={styles.allRuneStats}>
                            { 
                                [...lightStaticData.runeStat.primaryStyle, ...lightStaticData.runeStat.subStyle, ...lightStaticData.runeStat.stat].map((runeStatImg,index) => {
                                    const name = runeStatImg.match(/(?:\/)(\w+)(?:.\png)$/)[1]
                                    let key = `${index}-${name}`
                                    return (<img key={key} src={runeStatImg} alt="runeStat" />)
                                })
                            }
                        </div>
                        <div className={styles.itemPriority}>
                            <ItemPriority itemBuilInfo={lightStaticData.itemBuilInfo} availableVersion={availableVersion} allItemObj={allItemObj}/>
                        </div>
                        <div className={styles.skillPriority}>
                            <SkillPriority skillLevelUp={lightStaticData.skillLevelUp} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function LaneAll () {
               
        const allMatchLane = []
        // console.log(convertedLightStaticDataAry);
        if (convertedLightStaticDataAry.length !== 0) {
            convertedLightStaticDataAry.forEach((lightStaticData, index) => {

                let key=`${lightStaticData.matchId}-${lightStaticData.championName}`
                // console.log(lightStaticData);
                allMatchLane.push((
                    <div key={key}>
                        <EachLane lightStaticData={lightStaticData}availableVersion={availalbeVerionAry[0]} />
                    </div>
                ))   
            })

        }
        return (
            allMatchLane.length !== 0 
            ? (<div className={styles.allMatchLane}>
                {allMatchLane}
            </div>)
            : (<div></div>)
            
        )
    }

    return (

            <LaneAll />
  
    )
}

export default MatchList;