// import React, { useEffect, useState } from 'react';
import styles from './itemPriority.module.css'

function secToMin (second) {
    const min = Math.floor(second/60)
    const sec = second-min*60
    const minStr = min.toString().length === 1 ? `0${min}` : `${min}`
    const secStr = sec.toString().length === 1 ? `0${sec}` : `${sec}`
    const result = `${minStr}:${secStr}`
    return result
}


const ItemPriority = ({ itemBuilInfo, availableVersion, allItemObj }) => {
    // console.log(itemBuilInfo);
    // console.log(availableVersion);


    return (
        <div >
            <div className={styles.itemPriority_Header}>
                {
                    itemBuilInfo.iBP.map((itemId,index) => {
                        const itemInfo = allItemObj[itemId]
                        const spriteName = itemInfo.image.sprite;
                        const spritePositionX = itemInfo.image.x;
                        const spritePositionY = itemInfo.image.y;
                        const spriteUrl = `https://ddragon.leagueoflegends.com/cdn/${availableVersion}/img/sprite/${spriteName}`
                        const eachStyle = {
                            backgroundImage : `url(${spriteUrl})`,
                            backgroundPosition: `-${spritePositionX}px -${spritePositionY}px`
                        }
                        const key = `${index}-${itemId}`
                        return (
                            <div key={key} alt="coreItemBox">
                                <div style={eachStyle} alt="coreItem"></div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={styles.itemPriority_Deatail}>
                {
                    itemBuilInfo.iBIT.map((eachBuildInfoTimeline,index) => {
                        const time = eachBuildInfoTimeline.t
                        const itemAry = eachBuildInfoTimeline.i
                        const key = `${index}-${time}`
                        // const itemTimeline = []
                        const itemTimeline = itemAry.map((itemId, indexC) => {
                            const itemInfo = allItemObj[itemId]
                            const spriteName = itemInfo.image.sprite;
                            const spritePositionX = itemInfo.image.x;
                            const spritePositionY = itemInfo.image.y;
                            const spriteUrl = `https://ddragon.leagueoflegends.com/cdn/${availableVersion}/img/sprite/${spriteName}`
                            const eachStyle = {
                                backgroundImage : `url(${spriteUrl})`,
                                backgroundPosition: `-${spritePositionX}px -${spritePositionY}px`
                            }
                            const keyC = `${indexC}-${itemId}`
                            return (
                                <div key={keyC} alt="prorityTimelineItemBox">
                                    <div style={eachStyle} alt="prorityTimelineItem"></div>
                                </div>
                 
                            )
                        })
                        return (
                            <div key ={key} className={styles.infoTimelne_Items_Time}>
                                <div className={styles.infoTimelne_Items}>
                                    {itemTimeline}
                                </div>
                                <div className={styles.infoTimelne_Time}>{secToMin(time)}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ItemPriority;