// import {  gql } from '@apollo/client';
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const QueryData = async( availalbeVerionAry, allRuneIdObj, allSummonerSpellObj , languageAry,  positionAry, championAry) => {

    function convertLightStaticData (lightStaticData, availableVersion) {
        // console.log(lightStaticData);
        const positionSvg = {
            TOP : (<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 400 400"><g><path fill="#555d64" d="M247.059, 247.059V164.706H164.706v82.353h82.353ZM352.936, 352.933V82.537l-47.054, 46.875v176.47l-176.309.019L82.532,352.933h270.4Z"></path><path fill="#ffffff" d="M329.946, 47.1l-59.358,58.787H105.882V270.588L47.1,329.945,47.059,47.059Z"></path></g></svg>),
            JUNGLE : (<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 400 400"><path fill="#ffffff" d="M294.118, 35.294c-25.034,38.865-60.555,80.6-81.959,134.935,8.81,21.507, 17.469,42.872,23.135,65.065,5.088-12.873,5.51-23.4, 11.765-35.294C247, 141.447,268.9,97.375,294.118,35.294m-141.177, 200c-17.5-52.79-56-81.948-105.882-105.882,45.506,36.9,52.025, 88.47,58.823,141.176l44.035,38.96c17.313,18.887, 44.514,48.694,50.083,55.158, 53.589-111.119-39.6-244.759-94.118-329.412C137.292, 112.618,161.376,156.962, 152.941,235.294Zm94.118,58.824c1.1,9.873-.075,13.739,0, 23.529l47.059-47.059c6.8-52.706,13.318-104.28, 58.823-141.176C290.728,159.259,260.4,221.817, 247.059,294.118Z"></path></svg>),
            MIDDLE : (<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 400 400"><g><path fill="#555d64" d="M305.755,199.6L352.9,152.569l0.039,200.372h-200L200,305.882H305.883Zm-58.7-152.541L199.753,94.1H94.1L94.117,200,47.065,246.79V47.068Z"></path><path fill="#ffffff" d="M105.882,352.941l247.06-247.059V47.059H294.118L47.059,294.117v58.824h58.823Z"></path></g></svg>),
            BOTTOM : (<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 400 400"><g><path fill="#555d64" d="M152.942,152.941v82.353h82.352V152.941H152.942ZM47.064,47.067v270.4L93.6,270.436l0.52-176.318,176.31-.019,47.041-47.032H47.064Z"></path><path fill="#ffffff" d="M70.054,352.905l59.357-58.787H294.118V129.412L352.9,70.055l0.039,282.886Z"></path></g></svg>),
            UTILITY: (<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 400 400"><path fill="#ffffff" d="M317.647,200l-35.294-47.059h23.53c41.584,0,94.117-47.058,94.117-47.058H270.588l-35.294,35.293,23.53,82.354ZM245.026,35.3H153.673l-12.5,23.523L200,129.412l58.823-70.588L245.026,35.3m-33.262,117.64L200,164.706l-11.765-11.765L152.941,329.412,200,364.706l47.059-35.294ZM82.353,200l35.294-47.059H94.118C52.533,152.941,0,105.883,0,105.883H129.412l35.294,35.293-23.53,82.354Z"></path></svg>)
        }
        return {
            matchId: lightStaticData.mI,
            championName: lightStaticData.fC === "FiddleSticks" ? "Fiddlesticks" : lightStaticData.fC,
            championImg: `https://ddragon.leagueoflegends.com/cdn/${availableVersion}/img/champion/${lightStaticData.fC}.png`,
            vsChampionName: lightStaticData.vCN === "FiddleSticks" ? "Fiddlesticks" : lightStaticData.vCN,
            vsChampionImg: `https://ddragon.leagueoflegends.com/cdn/${availableVersion}/img/champion/${lightStaticData.vCN}.png`,
            teamPosition: lightStaticData.tP,
            teamPositionSvg: positionSvg[lightStaticData.tP],
            summonerName: lightStaticData.sN,
            summonerProNick: lightStaticData.sPN,
            summonerTier: lightStaticData.sT,
            summonerLP: lightStaticData.sL,
            runeStat: {
                primaryStyle: [
                    `https://ddragon.leagueoflegends.com/cdn/img/${allRuneIdObj[lightStaticData.tI.p.r.p[0]].icon}`,
                    `https://ddragon.leagueoflegends.com/cdn/img/${allRuneIdObj[lightStaticData.tI.p.r.p[1]].icon}`,
                    `https://ddragon.leagueoflegends.com/cdn/img/${allRuneIdObj[lightStaticData.tI.p.r.p[2]].icon}`,
                    `https://ddragon.leagueoflegends.com/cdn/img/${allRuneIdObj[lightStaticData.tI.p.r.p[3]].icon}`,
                ],
                subStyle: [
                    `https://ddragon.leagueoflegends.com/cdn/img/${allRuneIdObj[lightStaticData.tI.p.r.s[0]].icon}`,
                    `https://ddragon.leagueoflegends.com/cdn/img/${allRuneIdObj[lightStaticData.tI.p.r.s[1]].icon}`,
                ],
                stat: [
                    `https://ddragon.leagueoflegends.com/cdn/img/${allRuneIdObj[lightStaticData.tI.p.s[0]].icon}`,
                    `https://ddragon.leagueoflegends.com/cdn/img/${allRuneIdObj[lightStaticData.tI.p.s[1]].icon}`,
                    `https://ddragon.leagueoflegends.com/cdn/img/${allRuneIdObj[lightStaticData.tI.p.s[2]].icon}`,
                ],
            },
            spell: [
                `https://ddragon.leagueoflegends.com/cdn/${availableVersion}/img/spell/${allSummonerSpellObj[lightStaticData.tI.sP[0]].img}`,
                `https://ddragon.leagueoflegends.com/cdn/${availableVersion}/img/spell/${allSummonerSpellObj[lightStaticData.tI.sP[1]].img}`,
            ],
            kill : lightStaticData.tI.k,
            death : lightStaticData.tI.d,
            assist : lightStaticData.tI.a,
            lagestMultiKill : lightStaticData.tI.lMK,
            skillLevelUp : lightStaticData.sLU,
            itemBuilInfo : lightStaticData.iBI,
            version : lightStaticData.v,
            videoLink : `https://www.youtube.com/watch?v=${lightStaticData.videoId}`
        }
    
        // return {
        //     championName: lightStaticData.fC,
        // }
    }
    const lightStaticQueryStr = `{
        mI
        v
        fC
        tP
        vCN
        sT
        sL
        sN
        sPN
        tI {
        cI
        k
        d
        a
        lMK
        mk
        p {
            s
            r {
            p
            s
            }
        }
        sP
        tI
        w
        }
        sLU {
        Q
        W
        E
        R
        M
        }
        iBI {
        iB
        iBIT {
            t
            i
        }
        iBP
        }
        tKDA {
        tKA {
            t
            e
            k
            a
            m
        }
        tD {
            t
            e
            k
            a
            m
        }
        }
        gD
        videoId
        language
    }
    `
    function coverAryQueryStr(ary) {
        let queryStrAry =[]
        ary.forEach((each) => {
            queryStrAry.push(`"${each}"`)
        })
        return queryStrAry.join(', ')
    }
    languageAry = ['ko_KR', 'es_ES']
    const languageQueryAry = []
    languageAry.forEach((language) => {
        languageQueryAry.push(`${language} ${lightStaticQueryStr}`)
    })
    // const GET_LIST = gql`
    const GET_LIST = /* GraphQL */`
    query {
        queryMatch(positionAry: [${coverAryQueryStr(positionAry)}], championAry: [${coverAryQueryStr(championAry)}]) {
          ${languageQueryAry.join(`
          `)}
        }
      }`;
        // console.log(GET_LIST);
    
    // const covertedDataObj = {}
    async function initQuery () {
        // const covertedDataObj = await client.query({query:GET_LIST})
        const covertedDataObj = await API.graphql(graphqlOperation(GET_LIST))
        .then((data) => {
            // console.log(data);
            const newCovertedDataObj = {}
            languageAry.forEach((laguage) => {
                const convertedLightStaticDataAry = []
                data.data.queryMatch[laguage].forEach((lightStaticData, index) => {
                    // if (index > 20) {
                    //     return false
                    // }
                    const currentVerions = `${lightStaticData.v}.1`;
                    let availableVersion = currentVerions;
                    if (availalbeVerionAry.indexOf(currentVerions) === -1) {
                        availableVersion = availalbeVerionAry[0]
                    }
                    convertedLightStaticDataAry.push(convertLightStaticData(lightStaticData, availableVersion))
                })
                Object.assign(newCovertedDataObj, {
                    [laguage] : convertedLightStaticDataAry
                })
            })
            return newCovertedDataObj
        })
        return covertedDataObj
        // console.log(covertedDataObj);
    }
    let covertedDataObj = await initQuery().then() 
    // console.log(covertedDataObj);
    // console.log(covertedDataObj['ko_KR']);
    return {covertedDataObj}
}

export default QueryData;