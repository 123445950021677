// import React, { useEffect, useState } from 'react';
import styles from './videoThumbnailSlide.module.css'
// import { useQuery, gql } from '@apollo/client';
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import awsconfig from '../../commonComponent/aws-exports';
import { useEffect, useState } from 'react';

Amplify.configure(awsconfig);

const VideoThumbnailSlide = ({}) => {
    // const [videoIdAry, setVideoIdary] = useState([]);
    const [videoIdAry, setVideoIdAry] = useState([])
    useEffect(() => {
        LastVideoId()
    },[])
    async function LastVideoId () {
        // const GET_LIST = gql`
        const GET_LIST = /* GraphQL */`
        query {
            queryMatch(championAry: [], positionAry: []) {
                es_ES {
                  videoId
                }
                ko_KR {
                  videoId
                }
              }
        }
        `
        // const { loading, error, data} = useQuery(GET_LIST);
        const lastVideoId = []
        // if (loading) return 
        // if (error) return 
        // if (data) {
        const data = await API.graphql(graphqlOperation(GET_LIST))
            // console.log(data.data.queryMatch);

            Object.entries(data.data.queryMatch).forEach((eachChannel) => {
    
                lastVideoId.push(eachChannel[1][0].videoId)
                lastVideoId.push(eachChannel[1][1].videoId)
            })
        // }
        // return lastVideoId
        setVideoIdAry(lastVideoId)
    }
    // const videoIdAry = await LastVideoId()
    function focusThumbnailClick (e) {
        // console.log(e);

        let currentTarget = e.target;
        let currentAltAttribute = currentTarget.getAttribute('alt')
        const currentVideoId = currentTarget.getAttribute('videoid')
        if (currentAltAttribute === 'youtubeLink') {
        } else {
            while (currentAltAttribute !== "allThumbnail") {
                currentTarget = currentTarget.parentNode
                currentAltAttribute = currentTarget.getAttribute('alt')
            }
            const allThumnailEleWidth = currentTarget.offsetWidth
            const focusStyle = {
                transform : `scale(1.15)`,
                // transform : `perspective(50px) translateZ(10px)`,
                zIndex : "1"
            }
            const noneStyle = {
                transform : `scale(1.0)`,
                // transform : `perspective(50px) translateZ(0px)`,
                zIndex : "0"
            }
            for (let i = 0 ; i < currentTarget.children.length ; i++) {
                let eachEleVideoId = currentTarget.children[i].getAttribute('videoId')
                if (currentVideoId === eachEleVideoId) {
                    let translateXValue = (allThumnailEleWidth/2)-(currentTarget.children[i].getBoundingClientRect().width/2+currentTarget.children[i].getBoundingClientRect().left)
                    let currentTanslateXValue = currentTarget.style.transform !== "" 
                    ? currentTarget.style.transform.match(/(?:translateX\()(.+)(?:px\))/)[1]
                    : 0
                    currentTarget.style.transform = `translateX(${parseInt(currentTanslateXValue)+translateXValue}px)`                 
                    currentTarget.children[i].style.transform = focusStyle.transform
                    currentTarget.children[i].style.zIndex = focusStyle.zIndex
                } else {
                    currentTarget.children[i].style.transform = noneStyle.transform
                    currentTarget.children[i].style.zIndex = noneStyle.zIndex
                }
            }
        }
    }

    return (
        <div >
            <div onClick={focusThumbnailClick} className={styles.allThumbnail} alt='allThumbnail'>
                {
                    videoIdAry && videoIdAry.map((videoId, index) => {
                        const key = `${videoId}`
                        const thumbnailImg = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
                        const videoLink = `https://www.youtube.com/watch?v=${videoId}`
                        const thumbnailStyle ={
                            // transform: `perspective(500px) translateZ(${index*30}px)`
                        }
                        return (
                            <div key={key} style={thumbnailStyle} className={styles.eachThumbnail} alt='eachThumbnail' videoid={videoId}>
                                <div alt="thumbnail">
                                    <img src={thumbnailImg} videoid={videoId}alt="thumbnail" ></img>
                                </div>
                                <div alt="youtubeLink">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="48" height="48" viewBox="0 0 100 100">
                                        <path fill="#ffffff" d="M 95,50 5,95 5,5 z"/>
                                    </svg>
                                    <a  href={videoLink} target="_blank" rel="noreferrer" alt="youtubeLink">
                                    </a>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            
            
        </div>
    )
}

export default VideoThumbnailSlide;