import { useEffect, useState } from 'react';
import axios from 'axios';

const statMods = {
    '5008' : {
        icon: 'perk-images/StatMods/StatModsAdaptiveForceIcon.png',
        name: "Adaptive Force",
    },
    '5002' : {
        icon: 'perk-images/StatMods/StatModsArmorIcon.png',
        name: "Armor",
    },
    '5005' : {
        icon: 'perk-images/StatMods/StatModsAttackSpeedIcon.png',
        name: "Attack Speed",
    },
    '5003' : {
        icon: 'perk-images/StatMods/StatModsMagicResIcon.png',
        name: "Magic Resist",
    },
    '5001' : {
        icon: 'perk-images/StatMods/StatModsHealthScalingIcon.png',
        name: "Health Scaling",
    },
    '5007' : {
        icon: 'perk-images/StatMods/StatModsCDRScalingIcon.png',
        name: "Ability Haste"
    },
};
const FetchData = () => {
    const [allFetchData, setAllFetchData] = useState(null);
    useEffect(() => {
        async function fetchData() {
            const availalbeVerionAry = await axios.get('https://ddragon.leagueoflegends.com/api/versions.json').then((res) => res.data);
            // setAvailalbeVerionAry(availalbeVerionAry)
            const allRuneAry = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${availalbeVerionAry[0]}/data/en_US/runesReforged.json`).then((res) =>res.data);
            const allRuneIdObj = {};
            allRuneAry.forEach((res) => {
            allRuneIdObj[res.id] = {icon: res.icon};
            res.slots.forEach((resC) => {
                    resC.runes.forEach((resCC) => {
                        allRuneIdObj[resCC.id] = {icon: resCC.icon};
                    })
            })
            })
            const summonerSpellAry = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${availalbeVerionAry[0]}/data/en_US/summoner.json`).then((res) =>res.data.data);
            const allSummonerSpellObj = {};
            for (const res in summonerSpellAry) {
                let key = summonerSpellAry[res].key;
                // let name = summonerSpellAry[res].name;
                let img = summonerSpellAry[res].image.full;
                Object.assign(allSummonerSpellObj, {
                    [key] : {
                        // key: key,
                        // name: name,
                        img: img,
                    }
                });
            }
            const allChampionObj = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${availalbeVerionAry[0]}/data/en_US/champion.json`).then((res) =>res.data.data);
            const supportLanguageAry = ['en_US', 'ko_KR', 'ja_JP']
            for (const language of supportLanguageAry) {
                const languageChampionObj = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${availalbeVerionAry[0]}/data/${language}/champion.json`).then((res) =>res.data.data);
                for (const res of Object.entries(languageChampionObj)){
                    const championName = res[0]
                    const languageName = res[1].name;
                    allChampionObj[championName].language = { 
                    ...allChampionObj[championName].language,
                    [language] : languageName 
                    }
                }

            }
            const allItemObj = await axios.get(`https://ddragon.leagueoflegends.com/cdn/${availalbeVerionAry[0]}/data/en_US/item.json`).then((res) =>res.data.data);
            setAllFetchData({
                availalbeVerionAry,
                allRuneIdObj : {...allRuneIdObj,...statMods},
                allSummonerSpellObj,
                allChampionObj,
                allItemObj,
            })
        }
        fetchData()
        // console.log(fetchData()); 
    },[])
    // console.log(allFetchData);
    return allFetchData
}

export default FetchData;