// import React, { useEffect, useState } from 'react';
import styles from './skillPriority.module.css'
const SkillPriority = ({ skillLevelUp }) => {
    function SkillLane({skillName}) {
        const skillLane = []
        for (let i = 0 ; i < 19 ; i++) {
            let key = `${i}`
            if (i === 0) {
                skillLane.push((
                    <div key={key} className={styles.skillLane_Hightlight}>{skillName}</div>
                ))
            } else if (skillLevelUp[skillName].indexOf(i) !== -1) {
                skillLane.push((
                    <div key={key} className={styles.skillLane_Hightlight}>{i}</div>
                ))
            } else {
                skillLane.push((
                    <div key={key}></div>
                ))
            }
        }
        return (
            <div className={styles.skillLane}>
                {skillLane}
            </div>
        )
    }
    return (
        <div>
            <div className={styles.skillLevelUp_Header}>
                {
                    skillLevelUp['M'].map((skillName, index) => {
                        const key = `${index}-${skillName}`
                        return (
                            <div key={key}>
                                <div alt="skillMaster">{skillName}</div>
                            </div>
                        )
                    })
                }
            </div>
            <div className={styles.skillLevelUp_Deatail}>
                <SkillLane skillName={"Q"} />
                <SkillLane skillName={"W"} />
                <SkillLane skillName={"E"} />
                <SkillLane skillName={"R"} />
            </div>
        </div>
    )
}

export default SkillPriority;