import { useEffect, useRef } from 'react';

const TextToFit = (baseWidth) => {
    const element = useRef();
    useEffect(() => {
        if(element.current) {
            // console.log('start');

            const { current } = element;
            let textWidth = current.offsetWidth;
            // console.log(textWidth);
            let adjScale = baseWidth/textWidth;
            if (textWidth > baseWidth){
                current.style.display = "inline-block";
                current.style.transform = `scaleX(${adjScale})`;
                current.style.transformOrigin = `left`;
            }
        }
    },[])

    return { ref: element };
}

export default TextToFit;