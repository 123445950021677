import MatchList from './matchList';
import styles from './allMatchList.module.css'
import KrFlag from '../../images/flags/094-south-korea.svg'
import EuFlag from '../../images/flags/259-european-union.svg'
const flagSvg = {
    KR : (<img src={KrFlag} alt="flag" />),
    EUW : (<img src={EuFlag} alt="flag" />)
}
const AllMatchList = ({availalbeVerionAry, languageAry, allMatchData, allItemObj, allChampionObj}) => {
    // console.log(languageAry);
    return (
        <div className={styles.allMatchList}>
            {
                languageAry.map((language, index) => {
                    const serverNameObj = {
                        ko_KR : "KR",
                        es_ES : `EUW`,
                    }

                    // console.log(allMatchData.covertedDataObj ); 
                    let key = `${index}-${language}`
                    return (
                        <div key={key} className={styles.eachMatchList}>
                            {
                                allMatchData.covertedDataObj[language].length === 0 
                                ? (<div></div>)
                                : (
                                <div className={styles.eachServerTitle}>
                                    {flagSvg[serverNameObj[language]]}
                                    {serverNameObj[language]}
                                </div>
                                )
                            }
                            <MatchList laguage={language} availalbeVerionAry={availalbeVerionAry} allMatchData={allMatchData} allItemObj={allItemObj} allChampionObj={allChampionObj} />
                        </div>
                    )
                })
            }
        </div>
    ) 

}

export default AllMatchList;